import React from 'react';
import styled from 'styled-components';

import { Container, GridWrapper, H1 } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

import ImprovementsInFirstMonth from '../elements/ImprovementsInFirstMonth';
import BloodPressureChanges from '../elements/BloodPressureChanges';
import CholesterolLevelChanges from '../elements/CholesterolLevelChanges';
import BloodSugarChanges from '../elements/BloodSugarChanges';
import ReportedImprovements from '../elements/ReportedImprovements';
import StatisticsWithSameBloodPressure from '../elements/StatisticsWithSameBloodPressure';

interface IGridItem {
  withShadow?: boolean;
  gridRow?: string | null;
  gridColumn?: string | null;
}

const SummaryData: React.FC = () => {
  const { isMobile } = useQuery();

  return (
    <Container maxWidth="48.75rem">
      <H1
        fontSize="2.375rem"
        mobileFontSize="1.75rem"
        mobileLineHeight="2rem"
        margin={isMobile ? '1rem 0 0' : '2.5rem 0'}
        textAlign={isMobile ? 'left' : 'center'}
      >
        Your profile summary
      </H1>
      <GridWrapper
        columns={isMobile ? 1 : 2}
        gap={isMobile ? '1rem' : '2rem'}
        margin="0 0 2rem 0"
      >
        <GridItem>
          <ImprovementsInFirstMonth />
        </GridItem>
        <GridItem>
          <BloodPressureChanges />
        </GridItem>
      </GridWrapper>
      <StyledGridWrapper columns={isMobile ? 1 : 2} gap="2rem">
        <GridItem
          gridRow={!isMobile ? 'span 3' : '1'}
          gridColumn={!isMobile ? '1' : null}
          withShadow
        >
          <CholesterolLevelChanges />
        </GridItem>
        <GridItem
          gridRow={!isMobile ? 'span 3' : '3'}
          gridColumn={!isMobile ? '1' : null}
          withShadow
        >
          <StatisticsWithSameBloodPressure />
        </GridItem>
        <GridItem
          gridRow={!isMobile ? '1 / span 4' : '2'}
          gridColumn={!isMobile ? '2' : null}
          withShadow
        >
          <BloodSugarChanges />
        </GridItem>
        <GridItem
          gridRow={!isMobile ? 'span 2' : '4'}
          gridColumn={!isMobile ? '2' : null}
          withShadow
        >
          <ReportedImprovements />
        </GridItem>
      </StyledGridWrapper>
    </Container>
  );
};

export default SummaryData;

const StyledGridWrapper = styled(GridWrapper)`
  grid-template-rows: repeat(6, 1rf);
  grid-auto-rows: 1fr;

  @media ${mobile} {
    grid-auto-rows: unset;
  }
`;

const GridItem = styled.div<IGridItem>`
  background-color: transparent;
  box-shadow: ${props =>
    props.withShadow ? '0px 16px 32px rgba(8, 23, 62, 0.08)' : 'none'};
  grid-row: ${props => props.gridRow || 'auto'};
  border-radius: 16px;
  grid-column: ${props => props.gridColumn || ''};
`;
