import React from 'react';

import { PrivateRoute, Seo } from 'components';
import { useRouter } from 'apis/history';

import Header from './sections/Header';
import SummaryData from './sections/SummaryData';
import UntreatedHighCholesterol from './sections/UntreatedHighCholesterol';
import PersonalSummary from './sections/PersonalSummary';
import LessThinking from './sections/LessThinking';
import ManyLives from './sections/ManyLives';
import OurPromise from './sections/OurPromise';
import { Analytics } from 'apis/Analytics';

const Summary2: React.FC = () => {
  const { goToCheckout } = useRouter();
  const handleCtaClick = () => {
    Analytics.trackCTAButton('summary2');
    goToCheckout();
  };

  return (
    <>
      <Seo title="Summary | Pulsio" />
      <PrivateRoute>
        <Header onCtaClick={handleCtaClick} />
        <SummaryData />
        <UntreatedHighCholesterol onCtaClick={handleCtaClick} />
        <PersonalSummary />
        <LessThinking onCtaClick={handleCtaClick} />
        <ManyLives />
        <OurPromise onCtaClick={handleCtaClick} />
      </PrivateRoute>
    </>
  );
};

export default Summary2;
