import React from 'react';
import styled from 'styled-components';

import GroupOfHumans from 'assets/icons/group_of_humans.svg';

import { FlexWrapper, Regular } from 'components';

const StatisticsWithSameBloodPressure: React.FC = () => {
  return (
    <FlexWrapper
      alignItems="center"
      padding="2rem 1rem"
      height="100%"
      justifyContent="center"
      flexDirection="column"
    >
      <GroupOfHumans style={{ marginBottom: '1rem' }} />
      <StyledRegular textAlign="center">
        <RedHighlight>57%</RedHighlight> of our members have started with the
        same blood pressure level as you
      </StyledRegular>
    </FlexWrapper>
  );
};

export default StatisticsWithSameBloodPressure;

const StyledRegular = styled(Regular)`
  font-weight: 600;
  color: #0e4f6a;
`;

const RedHighlight = styled.span`
  color: #f06159;
`;
